import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";

const BrandResourceLink = ({img, label, url}) => {
  const {t} = useTranslation();
  let strLabel = 'DOWNLOAD '+label+' LOGO & IMAGES KIT';
  return (
      <div>
        {
          label === 'Digilock'
              ?
              <img src={img} alt={label} width={'300'} height={'144'} className={'digilogo'} />
              :
              <img src={img} alt={label} width={'359'} height={'248'} />
        }
        <a
            href={url}
            className={'btn btn--orange'}
        >{strLabel}</a>
      </div>
  )
}

export default BrandResourceLink;